import React from 'react';

import Login from '../Login';
import {LoginStyled, LoginContentStyled, HeadlineStyled} from '../styles';
import Text from '../../../Elements/Text/Text';

const LoginContainer = () => (
	<LoginStyled>
		<LoginContentStyled>
			<HeadlineStyled>Login</HeadlineStyled>
			<Text content="Melde dich mit deinen persönlichen Zugangsdaten an." />
			{/* <Infobox
				text='<h3 style="text-align: left;">Hinweis</h3><p style="text-align: left;">Liebe:r Nutzer:in,<br/><br/>um die Sicherheit der Seite weiterhin zu gewährleisten, haben wir den Anmeldeprozess aktualisiert. Du musst dich ab jetzt mit deiner E-Mail-Adresse und deinem Passwort einloggen, den Benutzernamen brauchst du ab dem 1.2.2023 zur Anmeldung nicht mehr!<br/><br/>Für deine erste Anmeldung im neuen System musst du einmalig dein Passwort neu erstellen. Dazu nutze bitte die "Passwort vergessen"-Funktion. Bei Fragen hilft dir unser <a href="https://mein.ms-life.de/service/service-team/" target="_blank">Service-Team</a> gerne weiter.</p>'
				theme="colored"
			/> */}
			<Login />
		</LoginContentStyled>
	</LoginStyled>
);

export default LoginContainer;
